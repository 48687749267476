



















import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import {CompanyData} from "@/types";

export default Vue.extend({
  name: "select-company" as string,

  data() {
    return {
      title: "Select company"
    }
  },

  async mounted(): Promise<void> {
    await this.setCompanies();
    this.fetchCompaniesImages();
  },

  computed: {
    ...mapGetters("companyModule", {
      companies: "GET_COMPANIES",
      hasCompany: "HAS_COMPANY"
    }),
    ...mapGetters("userModule", {
      getUserData: "GET_USER_DATA"
    }),
    getCompanyCount(): number {
      return this.getUserData.companies.length;
    },
    getCompanies(): Array<object> {
      return this.getUserData.companies;
    }
  },

  methods: {
    ...mapActions("companyModule", {
      setCompanies: 'FETCH_COMPANIES',
      setCompany: "SET_COMPANY"
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    fetchCompaniesImages(): void {
      for (let i = 0; i < this.companies.length; i++) {
        const company: CompanyData = this.companies[i];

        if (!!company.image_last_updated) {
          this.setImage({id: company.id, imageType: "venue_owner", multi: true});
        }
      }
    },
    insertCompanyImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`
    },
    async selectedCompany(companyId: number): Promise<void> {
      await this.setCompany(companyId);
      await this.$router.push("/dashboard");
      document.body.classList.add("home");
    }
  },
  beforeCreate() {
    document.body.className = "selectCompany";
  }
})
